.cardbox {
  border-radius: 4px;
  /* background: linear-gradient(283.86deg, #e7aa6f 0.5%, #5e54ae 97.97%); */
  width: 400px;
  height: 200px;
  margin-left: 30px;
  margin-top: 30px;
  transition: 0.5s;
  cursor: pointer;
}

.cardbox > img {
  margin-top: 50px;
  transition: 0.5s;
}

.cardbox:hover > img {
  transform: translate(-22px, -8px);
  /* transform: scale(1.1); */
}

.cardbox:hover {
  transform: scale(1.1);
  /* box-shadow: 24px 24px 39px #1b1b1b, -24px -24px 39px #353535; */
}

.letterboxd {
  background-color: #202830;
}

.tradebase {
  background-color: #1552f0;
}

.imax,
.efflux {
  background-color: white;
}

.youtube {
  background-color: #d73b3b;
}

.twitter {
  /* padding-left: 100px; */
  background-color: #6db2e0;
}

.linkedin {
  background-color: #0a66c2;
}

.tranzit {
  background: linear-gradient(283.86deg, #e7aa6f 0.5%, #5e54ae 97.97%);
}

.hymnsense {
  background: linear-gradient(256.57deg, #4bd250 -4.16%, #5e72a7 94.67%);
}

.cinesense {
  background: linear-gradient(257.95deg, #ff9900 -4.86%, #5e72a7 86.66%);
}

.github {
  background: #171516;
}

.bajaj {
  background-color: #6146ac;
}

.spotify {
  background-color: #1db954;
}
.blog {
  background-color: white;
}

@media only screen and (max-width: 768px) {
  /* .cardbox {
    width: 100px;
  } */
}
