.App {
  text-align: center;
}

.footer {
  padding-top: 100px;
  padding-bottom: 50px;
}

body {
  background-color: #282828 !important;
}

.logo-div {
  text-align: left;
  margin-left: 50px;
  margin-top: 25px;
}

.comingsoon h1 {
  color: white;
  font-weight: 500;
  font-size: 150px;
}

.display-photo {
  border-radius: 400px;
}

.dp-div {
  max-width: 800px;
  margin: auto;
  margin-bottom: 100px;
}

.dp-text {
  text-align: left;
  margin-top: 20px;
}

.dp-text > h1 {
  color: white;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: -10px;
}

.dp-text > h2 {
  color: #b5b5b5;
  line-height: 20px;
  width: 400px;
}

.items {
  margin: auto;
  max-width: 1350px;
}

.footer {
  color: white;
}

@media only screen and (max-width: 768px) {
  body {
    transform: scale(0.9);
  }
  .logo-div {
    margin: 0;
  }
  .dp-div {
    /* max-width: 400px; */
    margin: 80px 0px 80px 0px;
    transform: scale(1.2);
    text-align: center;
  }
  .display-photo {
    margin-left: 80px;
  }
  .dp-text > h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 0;
    width: 300px;
  }
  .dp-text > h2 {
    width: 300px;
  }

  .items {
    max-width: 400px;
  }
  .cardbox,
  .blog {
    width: 350px !important;
  }
}
